import React, { useState, useEffect } from "react";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import Loader from "../../Loader";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./BaseLocation.css";

const BaseLocation = () => {
  const [loading, setLoading] = useState();
  const [baseLocation, setBaseLocation] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const [location, setLocation] = useState({});

  const AtlasEmpID = userData.AtlasEmpID;
  const api_url = process.env.REACT_APP_API_URL;

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        const error = new Error(
          "Geolocation is not supported by this browser."
        );

        reject(error);
      }
    });
  };

  const checkBaseLocationExistence = async (id) => {
    try {
      const response = await axios.get(
        `${api_url}/baselocation/checkbaselocation/${id}`
      );
      if (response.data.isLocationAvailable === false) {
        setBaseLocation(false);
        // alert("Please send the base location for approval")
      }
    } catch (err) {
      console.error("There was an error updating the Request!", err);
      // return false;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // await handlegetPunches();
        await checkBaseLocationExistence(AtlasEmpID);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [AtlasEmpID]);

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      throw error;
    }
  };


  const handleCheckIn = async () => {
    if (baseLocation === false) {
      alert("You cannot check in without a base location.");
      return;
    }
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();

      const latitude = location.latitude;
      const longitude = location.longitude;
      // const latitude = 23.616846
      // const longitude = 58.247159
      if (!latitude || !longitude) {
        throw new Error("Location is not available");
      }
      if (!ipAddress) {
        throw new Error("IP Address is not available");
      }

      const isPunchedIn = await handleValidate();

      if (isPunchedIn === false) {
        const data = {
          userLocation: { latitude, longitude },
          IPAddress: ipAddress,
          AtlasEmpID: AtlasEmpID,
          Operation: "punchin",
          PunchDate: currentDate,
          PunchInTime: currentTime,
        };

        const response = await axios.post(`${api_url}/createpunchdetail`, data);

        if (response.data.success) {
          alert("Check-in successful");
        } else {
          alert("Error during check-in: " + response.data.message);
        }
      }
    } catch (error) {
      console.error("Error during check-in:", error);
      alert(
        "Error during check-in: " + error.response?.data?.message ||
          error.message
      );
    }
  };

  const handleValidate = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    if (!AtlasEmpID || !currentDate) {
      alert("AtlasEmpID and PunchDate are required.");
      return false;
    }

    try {
      const response = await axios.post(
        "https://atlas-om.app:30000/api/validatecheckin",
        {
          AtlasEmpID,
          PunchDate: currentDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.IsPunchedIn === true) {
        alert("Already punched in for today.");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during validation:", error);
      alert(
        "Error during validation: " + error.response?.data?.message ||
          error.message
      );
      return false;
    }
  };


  const handleCheckOut = async () => {
    if (baseLocation === false) {
      alert("You cannot check out without a base location.");
      return;
    }
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();
      const { latitude, longitude } = location;
      // const latitude = 23.616846
      // const longitude = 58.247159

      if (!latitude || !longitude || !ipAddress) {
        throw new Error("Location or IP address not available");
      }

      const data = {
        userLocation: { latitude, longitude },
        IPAddress: ipAddress,
        AtlasEmpID: AtlasEmpID,
        Operation: "punchout",
        PunchDate: currentDate,
        PunchOutTime: currentTime,
      };

      const response = await axios.post(`${api_url}/createpunchdetail`, data);

      if (response.data.success) {
        alert("Check-out successful");
      } else {
        alert("Error during check-out: " + response.data.message);
      }
    } catch (error) {
      console.error("Error during check-out:", error);
      alert(
        "Error during check-out: " + error.response?.data?.message ||
          error.message
      );
    }
  };

  const handleFetchBaseLocation = async () => {
    const loc = await getCurrentLocation(); // Fetch location again
    setLocation(loc); // Update the state with new location
  };

  const handleOpenModal = async () => {
    await handleFetchBaseLocation(); // Fetch location when opening the modal
  };

  const handleBaseLocationSend = async () => {
    // const location = await getCurrentLocation();
    // const location =

    await axios.post(
      `${api_url}/baselocation/sendbaselocation`,
      { userLocation: location, AtlasEmpID: AtlasEmpID },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const mapSrc =
    location.latitude && location.longitude
      ? `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1376.5732871228681!2d${location.longitude}!3d${location.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2som!4v1727679743582!5m2!1sen!2som`
      : "";

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <button
            type="button"
            onClick={handleOpenModal}
            data-bs-toggle="modal"
            data-bs-target="#baselocationmodal"
          >
            Send Base Location for Approval
          </button>
          <div className="punch-wrapper">
            <div className="d-flex gap-3">
              {/* {error && <p className="text-danger">{error}</p>} */}
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleCheckIn}
                  disabled={baseLocation === false}
                >
                  Punch In
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleCheckOut}
                  disabled={baseLocation === false}
                >
                  Punch Out
                </Button>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="baselocationmodal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="baselocationmodal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="baselocationmodal">
                    Send Base Location
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {location && (
                    <iframe
                      width="100%"
                      height="300"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowFullScreen=""
                      src={mapSrc}
                    ></iframe>

                    // <iframe
                    //   src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1376.5732871228681!2d58.21857209735558!3d23.60997825819043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2som!4v1727679743582!5m2!1sen!2som"
                    //   width="600"
                    //   height="450"
                    //   // style="border:0;"
                    //   allowfullscreen=""
                    //   loading="lazy"
                    //   referrerpolicy="no-referrer-when-downgrade"
                    // ></iframe>
                  )}
                </div>
                <div className="modal-footer">
                  <Link
                    to="/userpunch"
                    // state={{ mode: "add", AtlasEmpID: empID }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      endIcon={<SendIcon />}
                      data-bs-dismiss="modal"
                      onClick={handleBaseLocationSend}
                    >
                      Send
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BaseLocation;
