import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GeoLocation = () => {
  const [location, setLocation] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [error, setError] = useState(null);

  // Fetch IP address and location on page load
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch IP address
        const ipResponse = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(ipResponse.data.ip);

        // Fetch location
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              });
            },
            (error) => {
              console.error('Error fetching location:', error);
              setError('Unable to retrieve location.');
            }
          );
        } else {
          setError('Geolocation is not supported by this browser.');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error fetching data.');
      }
    };

    fetchData();
  }, []);

  // Function to handle button click
  const handleButtonClick = async () => {
    if (location && ipAddress) {
      try {
        const response = await axios.post('https://atlas-om.app:30000/api/timesheet/checklocation', {
          userLocation: location,
          IPAddress: ipAddress,
        });
        console.log('Response from backend:', response.data);
      } catch (error) {
        console.error('Error sending location data:', error);
        setError('Error sending data.');
      }
    } else {
      setError('Location or IP address not available.');
    }
  };

  return (
    <div>
      <h1>Location Tracker</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <p>Your IP Address: {ipAddress || 'Fetching...'}</p>
      <p>Your Location: {location ? `Lat: ${location.latitude}, Long: ${location.longitude}` : 'Fetching...'}</p>
      <button onClick={handleButtonClick}>Send Data</button>
    </div>
  );
};

export default GeoLocation;
