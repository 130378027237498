import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

import TextField from "@mui/material/TextField";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios from "axios";
import { useNavigate } from "react-router-dom";



// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   customContainer: {
//       width: '80%', // Adjust this value as needed
//       margin: 'auto', // Center the container horizontally
//   },
// }));
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {/* <Link color="inherit" href="https://www.atlas-om.com/" style={{paddingRight:'5px'}}>
        Atlas International 
      </Link> */}
      <Link
        color="inherit"
        href="https://www.atlas-om.com/"
        style={{ paddingRight: "5px", paddingLeft: "0.5rem" }}
      >
        Atlas International
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const api_url = process.env.REACT_APP_API_URL;
  

  const handlepost = async () => {
    try {
      const response = await axios.post(`${api_url}/login`, {
        username,
        password,
      });
      const token = response.data.accesstoken;
      // const IsReportingManager = response.data.IsReportingManager;
      if (!token) {
        alert("Invalid Username/Password");
      } else {
        // if(IsReportingManager){
        //   onToggle(true);  // Set to true if reporting manager
        // }
        // if (IsReportingManager) {
        //   localStorage.setItem('isReportingManager', true);
        // } else {
        //   localStorage.setItem('isReportingManager', false); 
        // }
        localStorage.setItem("token", token);
      }
    } catch (err) {
      console.error("Error during login:", err);
      alert("Invalid Username/Password");
      throw err;
    }
  };

  const fetchData = async (id) => {
                try {
                    const response = await axios.get(`${api_url}/user/getbasicdetails/${id}`);
                    // setData(response.data.data); 
                    sessionStorage.setItem("CurrentUserData", JSON.stringify(response.data.data));
                } catch (err) {
                    console.log("error", err);
                }
            };

  const handleget = async () => {
    const token1 = localStorage.getItem("token");
    if (token1 === null) {
      console.log("Token was null");
      return;
    }

    try {
      const response = await axios.get(`${api_url}/login/auth`, {
        headers: { token: token1 },
      });
      if (response.status === 200) {
        localStorage.setItem("AtlasEmpID",username);
        await fetchData(username);
        if (username === "AEC002" || username === "AEC057") 
        {
          navigate("/hrdash");
        }
        else{
          navigate("/timesheetMgmt");
        }
        // console.log("data from use context inside login",data);

      } else {
        navigate("/");
      }
    } catch (err) {
      console.error("Error during token validation:", err);
      navigate("/");
    }
  };

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Perform login request
      await handlepost();

      // Check token validity
      await handleget();
    } catch (error) {
      console.error("Error during submission:", error);
      // Optionally handle any errors here
    }
  };

  return (
    <>
      <div className="">
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                SIGN IN
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                  control={
                    <Checkbox value="remember" color="primary" disabled />
                  }
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Box className="d-flex justify-content-center">
                      <Link href="#" variant="body2">
                        <span style={{ color: "gray", cursor: "not-allowed" }}>
                          Forgot password?
                        </span>
                      </Link>
                    </Box>
                  </Grid>
                  {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
                </Grid>
              </Box>
            </Box>
            <Copyright sx={{ mt: 2, mb: 4 }} />
          </Container>
        </ThemeProvider>
      </div>
    </>
  );
}
