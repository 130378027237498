import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { Menu } from "../../header/Header";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { nationalities } from "../../../data/nationalities";

import Loader from "../../Loader";

import SignaturePicicon from "../../../assets/signature-sample.jpg";
import "./EmployeeForm.css";

const EmployeeForm = () => {
  const api_url = process.env.REACT_APP_API_URL;

  const formRef = useRef(null);

  // latest addition

  const navigate = useNavigate();
  const location = useLocation();
  const [employee, setEmployee] = useState({});
  const [dependents, setDependents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [reportingManagers, setReportingManagers] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [loading, setLoading] = useState(true);
  const { mode, empID } = location.state || {}; //Destructuring the object from the location state

  const signatureRef = useRef(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [fixedSalary, setTotalFixedSalary] = useState(0);
  const [years, setYears] = useState([]);
  // DisableNumberInputScroll();

  const [allowance_List, setAllowanceList] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const getTotalFixedSalary = (e, index) => {
    let total = 0;
    let temp = [...allowance_List];
    temp[index] = Number(e.target.value);
    setAllowanceList(temp);
    temp.forEach((item) => {
      total = total + item;
    });
    setTotalFixedSalary(total);
    setEmployee((prev) => ({
      ...prev,
      TotalFixedSalary: total,
    }));
  };

  const getDepartments = async () => {
    try {
      const response = await axios.get(`${api_url}/emp/GetDepartments`);
      let result = response.data.results;
      setDepartments(result);
      setSelectedDept("");
      return result;
    } catch (error) {
      console.error("There was an error fetching the departments!", error);
    }
  };

  const getDesignations = (e) => {
    axios
      .get(`${api_url}/emp/GetDesignations/${e}`)
      // .get(`${api_url}/emp/GetDesignations/${e}`)
      .then((response) => {
        setDesignations(response.data);
      })
      .catch((err) => {
        console.error("Error fetching Desingations", err);
      });
  };

  const getReportingManager = () => {
    axios.get(`${api_url}/emp/GetManagersList`).then((response) => {
      setReportingManagers(response.data);
    });
  };

  const getUserDetails = async (eid) => {
    try {
      const res = await axios.get(`${api_url}/emp/EditEmployee/${eid}`);
      setSignatureImage(res.data.imgPath);
      let emp = res.data.data;
      let depts = await getDepartments();
      let dept_id = depts.find(
        (dept) => dept.DepartmentName === emp.Department
      ).DeptID;
      getDesignations(dept_id);
      setSelectedDept(emp.Department);
      setEmployee(emp);
      let payroll_allowance_List = [
        emp.Basic,
        emp.HRA,
        emp.TA,
        emp.SA,
        emp.CA,
        emp.DA,
        emp.FA,
        emp.OA,
      ];
      setAllowanceList(payroll_allowance_List);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateEmployee = async (eid) => {
    try {
      await axios.put(`${api_url}/emp/UpdateEmployee/${eid}`, employee);
      return true; // Indicate success
    } catch (error) {
      console.error("There was an error updating the employee!", error);
      return false; // Indicate failure
    } finally {
      navigate("/empmgmt");
    }
  };

  useEffect(() => {
    getReportingManager();
    if (mode === "edit") {
      YearSelect();
      getUserDetails(empID);
    } else if (mode === "add") {
      getDepartments();
      getNextID();
      YearSelect();
      setLoading(false);
    }
  }, []);

  //Handle the input values and update in state
  const handleInputChanges = (event, field) => {
    let type = event.target.type;
    let val = event.target.value;
    if((type==="date")&& (val==="")){
      val = null;
    }
    //a function to handle input event, while receiving event and fieldname parameter and updates the state.
    let emp = { ...employee }; //emp is a temp varaible to store spread of employee state object which employee spreads its attributes to emp object
    emp[field] = val //since it is a dynamic key name we are using emp[field] and capturing input value and assigning to it
    setEmployee(emp); //adding the new object to state.
    // console.log(`${field}: ${event.target.value}`);
  };


  const handleDepartmentChange = (e) => {
    let value = e.target.value;
    if (value == "") {
      setSelectedDept(value);
      setDesignations([]);
    } else {
      const deptId = departments.find(
        (dept) => dept.DepartmentName === e.target.value
      ).DeptID;
      setSelectedDept(value);
      getDesignations(deptId);
    }
  };
  const YearSelect = () => {
    // Generate an array of years from 2006 to 2024
    const startYear = 1980;
    const endYear = 2024;
    const years_list = [];

    for (let year = startYear; year <= endYear; year++) {
      years_list.push(year);
    }
    setYears(years_list);
  };

  // --------------------------------------------------------------------

  //Get RefID and ID and set in state
  const getNextID = async() => {
    await axios
      // .get(`${api_url}/emp/nextempid`)
      .get(`${api_url}/emp/nextempid`)
      .then((res) => {
        // const empRefID = res.data.EmpRefID + 1;
        const empRefID = res.data.EmpRefID;
        // console.log("response from api",res.data.EmpRefID)
        const empIdPrefix = "AEC";
        const paddedempIDnumber = empRefID.toString().padStart(3, "0");
        const AtlasempID = empIdPrefix + paddedempIDnumber;
        const temp = { ...employee }; //destructuring the employee state using temp variable to update empid and refid
        temp["AtlasempID"] = AtlasempID;
        temp["EmpRefID"] = empRefID;
        setEmployee({ AtlasEmpID: AtlasempID, EmpRefID: empRefID });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const InsertEmpID = async () => {
    let empobj = { ...employee };
    const formData = new FormData();
    formData.append("AtlasEmpID", empobj.AtlasEmpID || "");
    formData.append("EmpRefID", empobj.EmpRefID || "");
    formData.append("Signature", signatureImage);
  
    if (empobj.AtlasEmpID) {
      try {
        const response = await axios.post(`${api_url}/emp/CreateEmployee`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Employee created:", response);
  
        const updateSuccess = await updateEmployee(employee.AtlasEmpID);
        return updateSuccess; // Return result of the update operation
      } catch (error) {
        console.log("Error creating employee:", error);
        return false; // Indicate failure if an error occurs
      }
    }
    return false; // Indicate failure if AtlasEmpID is not provided
  };
  

  const handleSubmit = async (eve) => {
    eve.preventDefault();
  
    let success = false;
  
    if (mode === "add") {
      success = await addEmployee();
    } else {
      success = await EditEmployee(empID);
    }
  
    if (success) {
      toast.success(mode === "add" ? "Employee Added Successfully!" : "Employee Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    } else {
      toast.error(mode === "add" ? "Employee Addition Failed" : "Employee Update Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };
  
  const addEmployee = async () => {
    const createSuccess = await InsertEmpID();
    if (createSuccess) {
      return true; // Employee was successfully created and updated
    }
    return false; // Indicate failure
  };
const EditEmployee = async (empid) => {
  return await updateEmployee(empid);
};

  // --------------------------------------------------------------------------------------------------

  const HandleSignatureClick = () => {
    signatureRef.current.click();
  };

  const HandleSignatureChange = async (e) => {
    const file = e.target.files[0];
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (file) {
      if (validTypes.includes(file.type)) {
        setSignatureImage(URL.createObjectURL(file)); // Display selected image
        await uploadSignature(file);
      } else {
        e.target.value = null;
        alert(
          "Invalid file type. Please select an image file (PNG, JPEG, JPG)."
        );
      }
    } else {
      alert("Please re-upload the image");
    }
  };

  const uploadSignature = async (file) => {
    try {
      let empid = employee.AtlasEmpID || empID;
      const formData = new FormData();
      formData.append("Signature", file);
      formData.append("AtlasEmpID", empID); // Assuming empID is needed on the server side

      const response = await axios.post(
        `${api_url}/emp/uploadsignature/${empid}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Error uploading signature", error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  //   ----------- dependents -----------------
  const labels = ["S.No", "Dependent Name", "Dependent Type", "DOB"];
  const handleDependents = (id) => {
    axios
      .get(`${api_url}/dependent/getdependentslist/${id}`)
      .then((res) => {
        const dependentlist = res.data;
        setDependents(dependentlist);
      })
      .catch((err) => console.log(err));
  };

  const handleWheel = (event) => {
    event.preventDefault(); // Prevent scrolling
  };

  // console.log(employee,"state of employee");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid border p-2">
          <form
            className=""
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="d-flex justify-content-between">
              <Menu link="/empmgmt" />
              <div className="emp-form-save-button">
                <Button type="submit" variant="contained" color="primary">
                  Save Details
                </Button>
              </div>
            </div>

            <div className="emp-form d-block">
              {/* Form Starts */}
              <div className="container form-wrapper border border-secondary">
                <div className="choose-box">
                  <button
                    type="button"
                    // className="btn btn-primary"
                    className={mode === "edit" ? "btn btn-primary" : "d-none"}
                    data-bs-toggle="modal"
                    data-bs-target="#dependentsmodal"
                    onClick={() => {
                      handleDependents(empID);
                    }}
                  >
                    Dependents
                  </button>

                  <div
                    className="modal fade"
                    id="dependentsmodal"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="dependentsmodal"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1 className="modal-title fs-5" id="dependentsmodal">
                            DEPENDENTS
                          </h1>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <table className="table table-hover table-responsive">
                            <thead>
                              <tr>
                                {labels.map((item, index) => (
                                  <th scope="col" key={index}>
                                    {item}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="table-body-font-size">
                              {dependents.map((item, index) => {
                                return (
                                  <tr className="py-0" key={index}>
                                    <td className="py-0  ">{index + 1}</td>
                                    {/* <td className="py-0">{item.DependentID}</td> */}
                                    <td className="py-0  ">
                                      {item.DependentName}
                                    </td>
                                    <td className="py-0">
                                      {item.DependentType}
                                    </td>
                                    <td className=" py-0 ">{item.DOB}</td>
                                    <td className="py-0">
                                      <Link
                                        to="/dependent"
                                        state={{
                                          mode: "edit",
                                          DepID: item.DependentID,
                                        }}
                                      >
                                        <EditIcon data-bs-dismiss="modal" />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="modal-footer">
                          <Link
                            to="/dependent"
                            state={{ mode: "add", AtlasEmpID: empID }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              endIcon={<AddIcon />}
                              data-bs-dismiss="modal"
                            >
                              Add
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form">
                  {/* Basic overview */}
                  <div className="row sub-wrapper ">
                    <h5 className="sub-wrapper-heading">Basic Overview</h5>
                    <div className="col">
                      <div className="row  inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label customlabel" htmlFor="empid">
                            AEC ID<span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="empid"
                            placeholder=""
                            disabled
                            readOnly
                            value={employee.AtlasEmpID}
                            onChange={(e) => {
                              handleInputChanges(e, "AtlasEmpID");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label customlabel"
                            htmlFor="firstname"
                          >
                            First Name<span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="firstname"
                            placeholder=""
                            // disabled={mode === "edit"}
                            value={employee.FirstName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "FirstName");
                            }}
                            required
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label customlabel"
                            htmlFor="lastname"
                          >
                            Last Name<span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="lastname"
                            placeholder=""
                            // disabled={mode === "edit"}
                            value={employee.LastName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "LastName");
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="department">
                            Department<span className="required"> *</span>
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "Department");
                              handleDepartmentChange(e);
                            }}
                            value={employee.Department || ""}
                            name="department"
                            className="form-input-select"
                            id="department"
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Department</option>
                            {departments.map((dept) => (
                              <option
                                key={dept.DeptID}
                                value={dept.DepartmentName}
                              >
                                {dept.DepartmentName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="designation">
                            Designation<span className="required"> *</span>
                          </label>
                          <select
                            className="form-input-select"
                            onChange={(e) => {
                              handleInputChanges(e, "Designation");
                            }}
                            id="designation"
                            name="designation"
                            value={employee.Designation || ""}
                            disabled={!selectedDept}
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Designation</option>
                            {designations.map((item) => (
                              <option
                                key={item.DesigID}
                                value={item.DesignationName}
                              >
                                {item.DesignationName}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="reporting-manager"
                          >
                            Reporting Manager
                            <span className="required"> *</span>
                          </label>
                          <select
                            className="form-input-select"
                            onChange={(e) => {
                              handleInputChanges(e, "ReportingManager");
                            }}
                            id="reporting-manager"
                            name="reporting-manager"
                            value={employee.ReportingManager || ""}
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Reporting Manager</option>
                            {reportingManagers.map((item) => (
                              <option
                                key={item.AtlasEmpID}
                                value={item.AtlasEmpID}
                              >
                                {item.AtlasEmpID} - {item.EmpName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="joiningDate">
                            Joining Date<span className="required"> *</span>
                          </label>
                          <input
                            id="joiningDate"
                            className="form-input-text"
                            type="date"
                            value={employee.JoiningDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "JoiningDate");
                            }}
                            required
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="add-emp-employee-type"
                          >
                            Employee Type<span className="required"> *</span>
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "EmployeeType");
                            }}
                            value={employee.EmployeeType}
                            name="employee-type"
                            className="form-input-select"
                            id="add_emp_employee_type"
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Employee type</option>
                            <option value="O">Own Staff</option>
                            <option value="S">Sub Contract</option>
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="add-emp-notice-period"
                          >
                            Notice Period
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "NoticePeriod");
                            }}
                            value={employee.NoticePeriod}
                            name="notice-period"
                            className="form-input-select"
                            id="add_emp_notice_period"
                            aria-label=".form-select-sm example"
                          >
                            <option value="">Select Notice Period</option>
                            <option value="30 Days">30 Days</option>
                            <option value="60 Days">60 Days</option>
                            <option value="90 Days">90 Days</option>
                          </select>
                        </div>
                      </div>
                      <div className="row inner-row">
                      <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="employeeworkplace">
                            Employee Work Place<span className="required"> *</span>
                          </label>
                          <select
                            name="employeeworkplace"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            onChange={(e) => {
                              handleInputChanges(e, "EmployeeWorkPlace");
                            }}
                            value={employee.EmployeeWorkPlace || ""}
                            required
                          >
                            <option value="">Select Work place</option>
                            <option value="In House">In House</option>
                            <option value="On Site">On Site</option>
                            </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Primary Information */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">Primary Information</h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="nationality">
                            Nationality<span className="required"> *</span>
                          </label>
                          <select
                            name="nationality"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            onChange={(e) => {
                              handleInputChanges(e, "Nationality");
                            }}
                            value={employee.Nationality || ""}
                            required
                          >
                          <option value="">Select Nationality</option>
                        {
                          nationalities.map((item,index)=>(
                            <option key={index} value={item}>{item}</option>
                          ))
                        }
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="empcategory">
                            Employee Category
                          </label>
                          <select
                            name="empcategory"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            value={employee.EmployeeCategory || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "EmployeeCategory");
                            }}
                          >
                            <option value="">Select Employee Category</option>
                            <option value="Category-A">Category A</option>
                            <option value="Cateogory-B">Category B</option>
                            <option value="Category-C">Category C</option>
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="ofcemail">
                            Official Email ID
                          </label>
                          <input
                            type="email"
                            className="form-input-email"
                            id="ofcemail"
                            aria-describedby="emailHelp"
                            value={employee.OfficialEmailID || ""}
                            maxLength="100"
                            onChange={(e) => {
                              handleInputChanges(e, "OfficialEmailID");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="landlinenumber"
                          >
                            LandlineNumber
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="landlinenumber"
                            placeholder=""
                            value={employee.LandlineNumber || ""}
                            maxLength="20"
                            onChange={(e) => {
                              handleInputChanges(e, "LandlineNumber");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="landlineextnum"
                          >
                            Landline Extension Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="landlineextnum"
                            placeholder=""
                            value={employee.LandlineExtNum || ""}
                            maxLength="20"
                            onChange={(e) => {
                              handleInputChanges(e, "LandlineExtNum");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="officialmobilenumber"
                          >
                            Official Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="officialmobilenumber"
                            placeholder=""
                            maxLength="20"
                            value={employee.OfficialMobileNumber || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "OfficialMobileNumber");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportnumber"
                          >
                            Passport Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="passportnumber"
                            placeholder=""
                            maxLength="20"
                            value={employee.PassportNumber || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "PassportNumber");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportissuedate"
                          >
                            Passport Issue Date
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="passportissuedate"
                            placeholder=""
                            value={employee.PassportIssueDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "PassportIssueDate");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportexpiry"
                          >
                            Passport Expiry
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="passportexpiry"
                            placeholder=""
                            value={employee.PassportExpiry || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "PassportExpiry");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportissueplace"
                          >
                            Passport Issue Place
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="passportissueplace"
                            placeholder=""
                            value={employee.PassportIssuePlace || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "PassportIssuePlace");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="visanumber">
                            Visa Number
                          </label>
                          <input
                            type="text"
                            className="form-input-number"
                            id="visanumber"
                            placeholder=""
                            value={employee.VisaNumber || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "VisaNumber");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="visaissuedate">
                            Visa Issue Date
                          </label>
                          <input
                            id="visaissuedate"
                            className="form-input-date"
                            type="date"
                            value={employee.VisaIssueDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "VisaIssueDate");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="visaexpirydate"
                          >
                            Visa Expiry Date
                          </label>
                          <input
                            id="visaexpirydate"
                            className="form-input-date"
                            type="date"
                            value={employee.VisaExpiryDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "VisaExpiryDate");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="civildnumber">
                            Civil ID Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="civildnumber"
                            placeholder=""
                            value={employee.CivilIDNumber || ""}
                            maxLength="20"
                            onChange={(e) => {
                              handleInputChanges(e, "CivilIDNumber");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="civilissuedate"
                          >
                            Civil Issue Date
                          </label>
                          <input
                            id="civilissuedate"
                            className="form-input-date"
                            type="date"
                            value={employee.CivilIssueDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "CivilIssueDate");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="civilexpirydate"
                          >
                            Civil Expiry Date
                          </label>
                          <input
                            id="civilexpirydate"
                            className="form-input-date"
                            type="date"
                            value={employee.CivilExpiryDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "CivilExpiryDate");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Career History */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">CAREER HISTORY</h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="qualificationname"
                          >
                            Highest Qualification
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="qualificationname"
                            placeholder=""
                            value={employee.QualificationName || ""}
                            maxLength="100"
                            onChange={(e) => {
                              handleInputChanges(e, "QualificationName");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="coursecompletedyear"
                          >
                            Course Completed Year
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "CourseCompletedYear");
                            }}
                            name="coursecompletedyear"
                            value={employee.CourseCompletedYear || ""}
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                          >
                            <option value="">Select the Year</option>
                            {/* {years.map} */}
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="universitycollege"
                          >
                            University / College
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="universitycollege"
                            placeholder=""
                            value={employee.UniversityCollege || ""}
                            maxLength="200"
                            onChange={(e) => {
                              handleInputChanges(e, "UniversityCollege");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="lastcompanyname"
                          >
                            Last Company Name
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="lastcompanyname"
                            placeholder=""
                            value={employee.LastCompanyName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "LastCompanyName");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="yearsinlastcompany"
                          >
                            Years In Last Company
                          </label>
                          <input
                            type="number"
                            className="form-input-number"
                            id="yearsinlastcompany"
                            placeholder=""
                            value={employee.YearsInLastCompany || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "YearsInLastCompany");
                            }}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="totalexperience"
                          >
                            Total Experience
                          </label>
                          <input
                            type="number"
                            className="form-input-number"
                            id="totalexperience"
                            placeholder=""
                            value={employee.TotalYearsExpWhileJoining || ""}
                            onChange={(e) => {
                              handleInputChanges(
                                e,
                                "TotalYearsExpWhileJoining"
                              );
                            }}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Personal Details */}
                  <div className="row sub-wrapper ">
                    <h5 className="sub-wrapper-heading">Personal Details</h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="dob">
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="dob"
                            placeholder=""
                            value={employee.DateofBirth || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "DateofBirth");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="gender">
                            Gender
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "Gender");
                            }}
                            name="gender"
                            value={employee.Gender || ""}
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                          >
                            <option defaultValue={""} value="">
                              Select Gender
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="maritalstatus">
                            Marital Status
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "MaritalStatus");
                            }}
                            name="maritalstatus"
                            value={employee.MaritalStatus || ""}
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                          >
                            <option defaultValue={""} value="Male">
                              Select Marital Status
                            </option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                          </select>
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-6 col-9">
                          <label
                            className="form-label"
                            htmlFor="localresidentialaddress"
                          >
                            Local Residential Address
                          </label>
                          <textarea
                            className="form-input-textarea"
                            id="localresidentialaddress"
                            rows="3"
                            columns="6"
                            onChange={(e) => {
                              handleInputChanges(e, "LocalResidentialAddress");
                            }}
                            value={employee.LocalResidentialAddress || ""}
                          ></textarea>
                        </div>
                        <div className="col-md-6 col-9">
                          <label
                            className="form-label"
                            htmlFor="permanentaddress"
                          >
                            Permanent Address
                          </label>
                          <textarea
                            className="form-input-textarea"
                            id="permanentaddress"
                            rows="3"
                            columns="3"
                            onChange={(e) => {
                              handleInputChanges(e, "PermanentAddress");
                            }}
                            value={employee.PermanentAddress || ""}
                          ></textarea>
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="personalemailid"
                          >
                            Personal Email ID
                          </label>
                          <input
                            type="email"
                            className="form-input-email"
                            id="personalemailid"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "PersonalEmailID");
                            }}
                            value={employee.PersonalEmailID || ""}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="personalmobilenumber"
                          >
                            Personal Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="personalmobilenumber"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "PersonalMobileNumber");
                            }}
                            value={employee.PersonalMobileNumber || ""}
                            maxLength="50"
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="emercontactname1"
                          >
                            Emergency Contact Name 1
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="emercontact1"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "EmergencyContactName1");
                            }}
                            value={employee.EmergencyContactName1 || ""}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="emercontactnumber1"
                          >
                            Emergency Contact Number 1
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="emercontactnumber1"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "EmergencyContactNumber1");
                            }}
                            value={employee.EmergencyContactNumber1 || ""}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="emercontactmail1"
                          >
                            Emergency Contact EmailID 1
                          </label>
                          <input
                            type="email"
                            className="form-input-email"
                            id="emercontactmail1"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "EmergencyContactEmailid1");
                            }}
                            value={employee.EmergencyContactEmailid1 || ""}
                            maxLength="50"
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="emercontactname2"
                          >
                            Emergency Contact Name 2
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="emercontactname2"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "EmergencyContactName2");
                            }}
                            value={employee.EmergencyContactName2 || ""}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="emercontactnum2"
                          >
                            Emergency Contact Number 2
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="emercontactnum2"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "EmergencyContactNumber2");
                            }}
                            value={employee.EmergencyContactNumber2 || ""}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="emercontactmailid2"
                          >
                            Emergency Contact EmailID 2
                          </label>
                          <input
                            type="email"
                            className="form-input-email"
                            id="emercontactmailid2"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "EmergencyContactEmailID2");
                            }}
                            value={employee.EmergencyContactEmailID2 || ""}
                            maxLength="50"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Salary Structure */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">Salary Structure</h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="basic">
                            Basic Pay
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="basic"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "Basic");
                              getTotalFixedSalary(e, 0);
                            }}
                            value={employee.Basic || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="hra">
                            HR Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="hra"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "HRA");
                              // handlePayroll(e, "HRA");
                              getTotalFixedSalary(e, 1);
                            }}
                            value={employee.HRA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="ta">
                            Travel Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="ta"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "TA");
                              // handlePayroll(e, "TA");
                              getTotalFixedSalary(e, 2);
                            }}
                            value={employee.TA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="sa">
                            Special Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="sa"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "SA");
                              // handlePayroll(e, "SA");
                              getTotalFixedSalary(e, 3);
                            }}
                            value={employee.SA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="ca">
                            Car Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="ca"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "CA");
                              // handlePayroll(e, "CA");
                              getTotalFixedSalary(e, 4);
                            }}
                            value={employee.CA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="da">
                            Desert Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="da"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "DA");
                              // handlePayroll(e, "DA");
                              getTotalFixedSalary(e, 5);
                            }}
                            value={employee.DA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="fa">
                            Food Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="fa"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "FA");
                              // handlePayroll(e, "FA");
                              getTotalFixedSalary(e, 6);
                            }}
                            value={employee.FA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="oa">
                            Other Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="oa"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "OA");
                              // handlePayroll(e, "OA");
                              getTotalFixedSalary(e, 7);
                            }}
                            value={employee.OA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label
                            className="form-label"
                            htmlFor="totalfixedsalary"
                          >
                            Total Fixed Salary
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="totalfixedsalary"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "TotalFixedSalary");
                              // handlePayroll(e, "Total");
                            }}
                            value={
                              fixedSalary || employee.TotalFixedSalary || 0
                            }
                            // value={employee.TotalFixedSalary || fixedSalary}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Compensation Details */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">
                      {" "}
                      Compensation Details
                    </h5>
                    <div className="row inner-row">
                      <div className="col-md-2 col-5 ">
                        <label
                          className="form-label"
                          htmlFor="mobilentitlement"
                        >
                          Mobile Entitlement
                        </label>
                      </div>
                      <div className="col-md-2 col-5 ">
                        <input
                          type="number"
                          className="form-input-number"
                          id="mobilentitlement"
                          placeholder=""
                          onChange={(e) => {
                            handleInputChanges(e, "MobileEntitlement");
                          }}
                          value={employee.MobileEntitlement || ""}
                          min={0}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      </div>
                      <div className="col-md-2 col-5 ">
                        <label className="form-label" htmlFor="airfarevalue">
                          Air Fare Value
                        </label>
                      </div>
                      <div className="col-md-2 col-5 ">
                        <input
                          type="number"
                          className="form-input-number"
                          id="airfarevalue"
                          placeholder=""
                          onChange={(e) => {
                            handleInputChanges(e, "AirFareValue");
                          }}
                          value={employee.AirFareValue || ""}
                          min={0}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* Bank Information and Digital Signatures */}
                  <div className="row sub-wrapper  ">
                    <h5 className="sub-wrapper-heading">
                      Bank Details & Digital Signature
                    </h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="bankname">
                            Bank Name
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="bankname"
                            placeholder=""
                            value={employee.BankName || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "BankName");
                            }}
                            maxLength="100"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="branchname">
                            Branch Name
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="branchname"
                            placeholder=""
                            value={employee.BranchName || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "BranchName");
                            }}
                            maxLength="100"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="accountnumber">
                            Account Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="accountnumber"
                            placeholder=""
                            value={employee.AccountNumber || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "AccountNumber");
                            }}
                            max="50"
                            min={0}
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="nameasinbank">
                            Name As In Bank
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="nameasinbank"
                            placeholder=""
                            value={employee.NameAsinBank || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "NameAsinBank");
                            }}
                            maxLength="100"
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                      <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="remarks">
                            Remarks
                          </label>
                          <textarea
                            onChange={(e) => {
                              handleInputChanges(e, "Remarks");
                            }}
                            value={employee.Remarks || ""}
                            className="form-control"
                            id="add_emp_remarks"
                            rows="3"
                            columns="6"
                            maxLength="1000"
                          ></textarea>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="docsigntext">
                            Document Sign Text
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="docsigntext"
                            placeholder=""
                            value={employee.DocumentSignText || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "DocumentSignText");
                            }}
                            maxLength="4"
                          />
                        </div>
                        
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="employeesign">
                            Employee Signature
                          </label>
                          <div className="d-flex flex-column align-items-start justify-content-center">
                            <div onClick={HandleSignatureClick}>
                              <img
                                // src={signatureImage || SignaturePicicon}
                                src={
                                  signatureImage
                                    ? signatureImage
                                    : SignaturePicicon
                                }
                                className="signature-img"
                                alt=""
                              />
                              <input
                                type="file"
                                className="d-none p-0"
                                id="employeesign"
                                onChange={HandleSignatureChange}
                                ref={signatureRef}
                                name="employeesign"
                                accept="image/png, image/jpeg, image/jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Submit Button */}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default EmployeeForm;
