import React, { useState } from "react";
import { Button } from "@mui/material";
import Loader from "../../../Loader";

const RecruitmentOfferLetter = () => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
         <div className="p-1 d-flex justify-content-end">
         <Button type="submit" variant="contained" color="primary">
                  Save Details
                </Button>
         </div>
         
            {/* Form Starts */}
            <form
              className=""
              // onSubmit={(e) => {
              //   handleSubmit(e);
              // }}
            >
              <div className="d-flex justify-content-between">
              <div className="emp-form-save-button">
                
              </div>
            </div>
            
              <div className="emp-form d-block">
                <div className="container form-wrapper border border-secondary">
                  <div className="row sub-wrapper ">
                    <h5 className="sub-wrapper-heading">Applicant Details</h5>
                    <div className="col-6">
                      {/* <label className="form-label" htmlFor="applicantfname">
                  Applicant's First Name<span className="required"> *</span>
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="applicantfname"
                  placeholder=""
                  value={applicantDetails.FirstName || ""}
                  maxLength="150"
                  onChange={(e) => {
                    handleInputChanges(e, "FirstName");
                  }}
                  required
                />
                 <label className="form-label" htmlFor="applicantlname">
                  Applicant's Last Name<span className="required"> *</span>
                </label>
                <input
                  type="text"
                  className="form-input-text"
                  id="applicantlname"
                  placeholder=""
                  value={applicantDetails.LastName || ""}
                  maxLength="150"
                  onChange={(e) => {
                    handleInputChanges(e, "LastName");
                  }}
                  required
                />

                <label className="form-label" htmlFor="department">
                  Department<span className="required"> *</span>
                </label>
                <select
                  onChange={(e) => {
                    handleInputChanges(e, "Department");
                    handleDepartmentChange(e);
                  }}
                  value={applicantDetails.Department || ""}
                  name="department"
                  className="form-input-select"
                  id="department"
                  aria-label=".form-select-sm example"
                  required
                >
                  <option value="">Select Department</option>
                  {departments.map((dept) => (
                    <option key={dept.DeptID} value={dept.DepartmentName}>
                      {dept.DepartmentName}
                    </option>
                  ))}
                </select>

                <label className="form-label" htmlFor="designation">
                  Designation<span className="required"> *</span>
                </label>
                <select
                  className="form-input-select"
                  onChange={(e) => {
                    handleInputChanges(e, "Designation");
                  }}
                  id="designation"
                  name="designation"
                  value={applicantDetails.Designation || ""}
                  disabled={!selectedDept}
                  aria-label=".form-select-sm example"
                  required
                >
                  <option value="">Select Designation</option>
                  {designations.map((item) => (
                    <option key={item.DesigID} value={item.DesignationName}>
                      {item.DesignationName}{" "}
                    </option>
                  ))}
                </select>

                
                <div className="m-2">
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    accept="application/pdf"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
        </div>
      )}
    </>
  );
};

export default RecruitmentOfferLetter;
