import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./LeaveRequestForm.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Loader from "../../../Loader";
import Button from "@mui/material/Button";
import axios from "axios";
import { toast } from "react-toastify";

const LeaveRequestForm = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const api_url = process.env.REACT_APP_API_URL;
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  // const [isNewRequest, setIsNewRequest] = useState(location.state?.isNewRequest || null);
  const [reqid, setReqid] = useState(location.state?.reqid || null);

  const [requestDetails, setRequestDetails] = useState({});

  const getLatestRequestID = async () => {
    try {
      const response = await axios.get(
        `${api_url}/leaverequest/getlatestrequestid`
      );
      const requestid = response.data?.RequestID;
      if (requestid) {
        return requestid;
      } else {
        throw new Error("RequestID not found in response");
      }
    } catch (error) {
      console.error("Error fetching Request ID:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  useEffect(() => {
    if (reqid === "new") {
      setRequestDetails({
        ...requestDetails,
        AtlasEmpID: userData?.AtlasEmpID || "",
        EmpName: userData?.EmpName || "",
        Department: userData?.Department || "",
        Designation: userData?.Designation || "",
        ReportingManager: userData?.ReportingManager || "",
        ReportingManagerName: userData?.ReportingManagerName || "",
        HRManagerName: userData?.HRManagerName || "",
      });
      setLoading(false);
    } else {
      // Fetch data from API for existing request
      axios
        .get(`${api_url}/requests/getrequestdetails/${reqid}`)
        .then((res) => {
          console.log("response of getting the request", res.data.data);
          setRequestDetails(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching request details:", error);
          setLoading(false);
        });
    }
  }, [reqid]);

  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const year = today.getFullYear();
  const date = today.getDate().toString().padStart(2, "0");

  const hours = today.getHours().toString().padStart(2, "0");
  const minutes = today.getMinutes().toString().padStart(2, "0");
  const seconds = today.getSeconds().toString().padStart(2, "0");

  const currentDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;

  const InsertRequestDetails = async (payload) => {
    try {
      const response = await axios.post(
        `${api_url}/requests/createleaverequest`,
        payload,
        {
          headers: {
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
      if (response.data.success === true) {
        toast.success("Request Saved Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      }
      console.log(
        "Request details created successfully",
        response.data.success
      );
      return response.data.success;
    } catch (error) {
      console.log("Error:", error);
      return false;
    }
  };

  const handleInputChanges = (event, field) => {
    let type = event.target.type;
    let val = event.target.value;
    if (type === "date" && val === "") {
      val = null;
    }
    let request = { ...requestDetails };
    request[field] = val;
    setRequestDetails(request);
  };

  const CalculateDateDifference = (startdate, enddate) => {
    if (startdate && enddate) {
      const startDate = new Date(startdate);
      const endDate = new Date(enddate);

      // Set the time to the start of the day for both dates
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      // Calculate the time difference
      const timeDifference = endDate - startDate;

      // Calculate the difference in days, adding 1 to account for the same day
      const daysDifference =
        1 + Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      // Return formatted output
      if (daysDifference <= 0) {
        return "1 day"; // If the dates are the same or invalid
      }

      return daysDifference === 1 ? `1 day` : `${daysDifference} days`;
    }
    return null;
  };

  const difference = CalculateDateDifference(
    requestDetails.LeaveStarts,
    requestDetails.LeaveEnds
  );
  console.log("requestDetails state", requestDetails);

  const handleupdaterequest = async (id, payload) => {
    try {
      const response = await axios.put(
        `${api_url}/request/updaterequestdetails/${id}`,
        payload
      );
      return response.data.success;
    } catch (error) {
      console.error("There was an error updating the Request!", error);
      return false;
    } finally {
      // navigate("/requests/leave-request-list");
    }
  };

  const handleSubmit = async (e, operation) => {
    e.preventDefault();

    if (!requestDetails.LeaveType) {
      alert("Please select a Leave Type.");
      return;
    }
    if (!requestDetails.LeaveStarts) {
      alert("Please select a Leave Start date.");
      return;
    }
    if (!requestDetails.LeaveEnds) {
      alert("Please select a Leave End date.");
      return;
    }

    //operation save begins
    if (operation === "create") {
      //manager holding the request by adding comments and saving
      if (requestDetails.AtlasEmpID != userData.AtlasEmpID) {
        let temp = {
          ManagerComments: requestDetails.ManagerComments,
          HRComments: requestDetails.HRComments,
        };
        await handleupdaterequest(reqid, temp);
        toast.success("Request Saved Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
        navigate("/requests/leave-approvals-list");
      } else if (reqid != "new" && requestDetails.StatusInd === "C") {
        let temp = {
          LeaveType: requestDetails.LeaveType,
          LeaveStarts: requestDetails.LeaveStarts,
          LeaveEnds: requestDetails.LeaveEnds,
        };
        await handleupdaterequest(reqid, temp);
        toast.success("Request Saved Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          theme: "light",
          className: "custom-toast",
        });
      } else {
        let temp = {
          ...requestDetails,
          StatusInd: "C", //Created
        };
        let success = await InsertRequestDetails(temp);
        if (success) {
          try {
            setLoading(true);
            const latestId = await getLatestRequestID();
            setReqid(latestId);
            setLoading(false);
          } catch (error) {
            console.error("Failed to fetch latest applicant ID:", error);
            setLoading(false); // Stop loading even on error
          }
        }
      }
    }
    //Operation save is end

    //Submit Operation begins
    else if (operation === "submit") {
      //HR submission

      // if (userData.Designation === "HR Manager") {
      if (userData.AtlasEmpID === "AEC002") {
        //AEC ID to be taken
        let payload = {
          ...requestDetails,
          StatusInd: "A",
          HRApproval: "N",
          ReportingManagerApproval: "N",
          SubmittedDate: currentDateTime,
        };

        //Checking whether it is a new request

        if (reqid === "new") {
          await InsertRequestDetails(payload);
        } else {
          let payload = {
            StatusInd: "A",
            HRApproval: "N",
            ReportingManagerApproval: "N",
            SubmittedDate: currentDateTime,
          };
          await handleupdaterequest(reqid, payload);
        }
      }
      //Manager Submission
      else if (
        userData.IsReportingManager === 1 &&
        userData.AtlasEmpID?.trim() != "AEC002"
      ) {
        if (reqid === "new") {
          let payload = {
            ...requestDetails,
            StatusInd: "P",
            HRApproval: "P",
            ReportingManagerApproval: "N",
            SubmittedDate: currentDateTime,
          };
          await InsertRequestDetails(payload);
        } else {
          let payload = {
            StatusInd: "P",
            HRApproval: "P",
            ReportingManagerApproval: "N",
            SubmittedDate: currentDateTime,
          };
          await handleupdaterequest(reqid, payload);
        }
      }

      //Normal User
      else if (userData.IsReportingManager === 0) {
        //Checking reporting manager and hr manager same or not
        if (userData.ReportingManager?.trim() === userData.HRManager?.trim()) {
          if (reqid === "new") {
            let payload = {
              ...requestDetails,
              StatusInd: "P",
              HRApproval: "P",
              ReportingManagerApproval: "N",
              SubmittedDate: currentDateTime,
            };
            await InsertRequestDetails(payload);
          } else {
            let payload = {
              StatusInd: "P",
              HRApproval: "P",
              ReportingManagerApproval: "N",
              SubmittedDate: currentDateTime,
            };
            await handleupdaterequest(reqid, payload);
          }
        } else {
          if (reqid === "new") {
            let payload = {
              ...requestDetails,
              StatusInd: "P",
              HRApproval: null,
              ReportingManagerApproval: "P",
              SubmittedDate: currentDateTime,
            };
            await InsertRequestDetails(payload);
          } else {
            let payload = {
              StatusInd: "P",
              ReportingManagerApproval: "P",
              SubmittedDate: currentDateTime,
            };
            await handleupdaterequest(reqid, payload);
          }
        }
      }
      toast.success("Request Submitted Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
      navigate("/requests/leave-request-list", {
        state: { list: "request-list" },
      });
    }

    //submit operation ends

    //Approval
    else if (operation === "approve") {
      //HR Approval
      // if (userData.Designation.trim() === "HR Manager") {
      if (userData.AtlasEmpID?.trim() === "AEC002") {
        let payload = {
          StatusInd: "A",
          HRApproval: "A",
          HRApprovedDate: currentDateTime,
        };
        await handleupdaterequest(reqid, payload);
      }
      //Manager Approval
      else {
        let payload = {
          StatusInd: "P",
          ReportingManagerApproval: "A",
          HRApproval: "P",
          ReportingManagerApprovedDate: currentDateTime,
        };
        await handleupdaterequest(reqid, payload);
      }
      toast.success("Request Approved Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
      navigate("/requests/leave-approvals-list");
    }
    //Rejection
    else if (operation === "reject") {
      //HR Rejection
      // if (userData.Designation.trim() === "HR Manager") {
      if (userData.AtlasEmpID?.trim() === "AEC002") {
        if (
          !requestDetails.HRComments ||
          requestDetails.HRComments?.trim() === ""
        ) {
          alert("Please fill the rejection reason");
          return;
        }
        let payload = {
          StatusInd: "R",
          HRApproval: "R",
          HRComments: requestDetails.HRComments,
        };
        await handleupdaterequest(reqid, payload);
      }
      //ManagerRejection
      else {
        if (
          !requestDetails.ManagerComments ||
          requestDetails.ManagerComments?.trim() === ""
        ) {
          alert("Please fill the rejection reason");
          return;
        }
        let payload = {
          StatusInd: "R",
          ReportingManagerApproval: "R",
          ManagerComments: requestDetails.ManagerComments,
        };
        await handleupdaterequest(reqid, payload);
      }
      navigate("/requests/leave-approvals-list");
      toast.success("Request Rejected Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
        className: "custom-toast",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <Link
              to={
                requestDetails.AtlasEmpID === userData.AtlasEmpID
                  ?"/requests/leave-request-list" 
                  : "/requests/leave-approvals-list"
              }
            >
              <KeyboardBackspaceIcon
                style={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              />
              <p className=" p-2 d-inline">Go back</p>
            </Link>
          </div>
          <div className="emp-form d-block">
            <form className="">
              <div className="container-fluid">
                <div className="container p-3 form-wrapper border border-secondary">
                  <div className="row sub-wrapper ">
                    <div className="col ">
                      <h5 className="sub-wrapper-heading">
                        Leave Request Details
                      </h5>
                      <div className="row">
                        <div className="col-7 ">
                          <div className="row">
                            <div className="col">
                              <div className="row">
                                <div className="col">
                                <label
                                className="form-label customlabel"
                                htmlFor="empid"
                              >
                                AEC ID
                              </label>
                              <input
                                type="text"
                                className="form-input-text"
                                id="empid"
                                placeholder=""
                                disabled
                                value={requestDetails.AtlasEmpID}
                                onChange={(e) => {
                                  handleInputChanges(e, "AtlasEmpID");
                                }}
                              />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                <label
                                className="form-label customlabel"
                                htmlFor="employeename"
                              >
                                Employee Name
                              </label>
                              <input
                                type="text"
                                className="form-input-text"
                                id="employeename"
                                placeholder=""
                                maxLength="50"
                                disabled
                                value={requestDetails.EmpName}
                                onChange={(e) => {
                                  handleInputChanges(e, "EmpName");
                                }}
                              />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                <label
                                className="form-label"
                                htmlFor="department"
                              >
                                Department
                              </label>
                              <input
                                type="text"
                                className="form-input-text"
                                id="department"
                                placeholder=""
                                maxLength="50"
                                disabled
                                value={requestDetails.Department}
                                onChange={(e) => {
                                  handleInputChanges(e, "Department");
                                }}
                              />
                                </div>
                              </div>
                            
                             
                              <div className="row">
                                <div className="col">
                                <label
                                className="form-label"
                                htmlFor="designation"
                              >
                                Designation
                              </label>
                              <input
                                type="text"
                                className="form-input-text"
                                id="desgination"
                                placeholder=""
                                maxLength="50"
                                disabled
                                value={requestDetails.Designation}
                                onChange={(e) => {
                                  handleInputChanges(e, "Designation");
                                }}
                              />
                                </div>
                              </div>
                             <div className="row">
                              <div className="col">
                              <label className="form-label" htmlFor="leavetype">
                                Leave Type<span className="required"> *</span>
                              </label>
                              <select
                                name="leavetype"
                                className="form-input-select"
                                aria-label=".form-select-sm example"
                                value={requestDetails.LeaveType}
                                onChange={(e) => {
                                  handleInputChanges(e, "LeaveType");
                                }}
                                disabled={
                                  reqid != "new" &&
                                  requestDetails.StatusInd != "C"
                                }
                              >
                                <option value="">Select Leave Type</option>
                                <option value="SL">Sick Leave</option>
                                <option value="AL">Annual Leave</option>
                                <option value="EL">Emergency Leave</option>
                              </select>

                              </div>
                             </div>
                              <div className="row">
                                <div className="col">
                                <label
                                className="form-label"
                                htmlFor="leavestarts"
                              >
                                Leave Starts<span className="required"> *</span>
                              </label>
                              <input
                                type="date"
                                className="form-input-date"
                                id="leavestarts"
                                placeholder=""
                                onChange={(e) => {
                                  handleInputChanges(e, "LeaveStarts");
                                }}
                                value={requestDetails.LeaveStarts}
                                disabled={
                                  reqid != "new" &&
                                  requestDetails.StatusInd != "C"
                                }
                                required
                              />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col">
                                <label className="form-label" htmlFor="leaveends">
                                Leave Ends<span className="required"> *</span>
                              </label>
                              <input
                                type="date"
                                className="form-input-date"
                                id="leaveends"
                                placeholder=""
                                onChange={(e) => {
                                  handleInputChanges(e, "LeaveEnds");
                                }}
                                value={requestDetails.LeaveEnds}
                                disabled={
                                  reqid != "new" &&
                                  requestDetails.StatusInd != "C"
                                }
                                required
                              />
                                </div>
                              </div>
                             
                             
                              {reqid === "new" || ((reqid!="new")&& (requestDetails?.StatusInd?.trim() === "C" ||
                              requestDetails.ReportingManagerApproval ===
                                "N"))
                              ? (
                                ""
                              ) : (
                                <>
                                  <label
                                    className="form-label"
                                    htmlFor="managercomments"
                                  >
                                    Manager Comments
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="managercomments"
                                    rows="3"
                                    columns="6"
                                    maxLength="1000"
                                    value={requestDetails.ManagerComments || ""}
                                    onChange={(e) => {
                                      handleInputChanges(e, "ManagerComments");
                                    }}
                                    //if it is not a new request and user is not reporting manager and user's desingation is HR manager
                                    readOnly={
                                      (reqid != "new" &&
                                        userData.IsReportingManager === 0) ||
                                      userData.Designation?.trim() ===
                                        "HR Manager" || requestDetails.ReportingManagerApproval != "P"
                                    }
                                  ></textarea>
                                </>
                              )}

                              {reqid === "new" || 
                              requestDetails.StatusInd?.trim() === "C" ||
                              requestDetails.HRApproval === "N" ? (
                                ""
                              ) : (
                                <>
                                  <label
                                    className="form-label"
                                    htmlFor="hrcomments"
                                  >
                                    HR Comments
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="hrcomments"
                                    rows="3"
                                    columns="6"
                                    maxLength="1000"
                                    value={requestDetails.HRComments || ""}
                                    onChange={(e) => {
                                      handleInputChanges(e, "HRComments");
                                    }}
                                    readOnly={
                                      userData.Designation?.trim() !== "HR Manager" ||
                                      (userData.AtlasEmpID === "AEC002" && requestDetails.HRApproval?.trim() === "A")
                                    }

                                    //()
                                  ></textarea>
                                </>
                              )}
                              
                            </div>
                            {/* no of days */}
                            <div className="col d-flex align-items-center">
                                {requestDetails.LeaveStarts &&
                                  requestDetails.LeaveEnds && (
                                    <div className="ms-5">
                                      <label
                                        className="form-label customlabel"
                                        htmlFor="approvalmanager"
                                      >
                                        Number of days:{" "}
                                      </label>
                                      <p className="d-inline ms-2 fs-6">
                                        {difference}
                                      </p>
                                    </div>
                                  )}
                              </div>
                          </div>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-4">
                         
                          <div className="row">
                            <div className="col">
                            <label
                            className="form-label customlabel"
                            htmlFor="approvalmanager"
                          >
                            Approval Manager
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="empid"
                            placeholder=""
                            disabled
                            readOnly
                            value={requestDetails.ReportingManagerName}
                            onChange={(e) => {
                              handleInputChanges(e, "ReportingManagerName");
                            }}
                          />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                            <label
                            className="form-label customlabel"
                            htmlFor="hrmanager"
                          >
                            HR Manager
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="hrmanager"
                            placeholder=""
                            disabled
                            readOnly
                            value={"Rahima Nasser Al Hoqani"}
                          />
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col">
                            <div className="d-inline">
                          {reqid != "new" ? (
                            <div>
                              <label
                                className="form-label customlabel"
                                htmlFor="empid"
                              >
                                Status: 
                              </label>
                              <div className="ps-2 d-inline">
                                {requestDetails.StatusInd === "C" ? (
                                  <span className="custom-font-size fw-semibold">
                                    Saved
                                  </span>
                                ) : (
                                  ""
                                )}
                                {requestDetails.StatusInd === "A" ? (
                                  <span className="custom-font-size text-success fw-semibold">
                                    Approved
                                  </span>
                                ) : (
                                  ""
                                )}
                                {requestDetails.StatusInd === "P" ? (
                                  <span className="custom-font-size text-warning fw-semibold">
                                    Pending for Approval
                                    {requestDetails.ReportingManagerApproval ===
                                    "P"
                                      ? ` - ${requestDetails.ReportingManagerName}`
                                      : requestDetails.HRApproval === "P"
                                      ? ` - ${requestDetails.HRManagerName}`
                                      : ""}
                                  </span>
                                ) : null}

                                {requestDetails.StatusInd === "R" ? (
                                  <span className="custom-font-size  text-danger fw-semibold">
                                    Rejected 
                                    {requestDetails.ReportingManagerApproval ===
                                    "R"
                                      ? ` - ${requestDetails.ReportingManagerName}`
                                      : requestDetails.HRApproval === "R"
                                      ? ` - ${requestDetails.HRManagerName}`
                                      : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                            </div>
                          </div>
                        

                        
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    {/* Once Manager Approved This part won't visible.  */}
                    {((userData.AtlasEmpID==="AEC002")&&(requestDetails.HRApproval?.trim()==="A"))||(userData.AtlasEmpID !== "AEC002" && (
    (requestDetails.ReportingManagerApproval?.trim() !== (null || "P") &&
    requestDetails.ReportingManager?.trim() === userData.AtlasEmpID?.trim()) || 
    ((userData.AtlasEmpID === "AEC002") && (requestDetails.HRApproval?.trim() !== "P")) 
  ))?

  // { userData.AtlasEmpID !== "AEC002" && 
  // (
  //   userData.AtlasEmpID === "AEC002" && 
  //   (requestDetails.HRApproval?.trim() === "A" || requestDetails.HRApproval?.trim() === "R")
  // ) && (
  //   (requestDetails.ReportingManagerApproval?.trim() !== (null || "P") &&
  //   requestDetails.ReportingManager?.trim() === userData.AtlasEmpID?.trim()) || 
  //   ((userData.AtlasEmpID === "AEC002") && (requestDetails.HRApproval?.trim() !== "P"))
  // )?
                     ""
                    :
                    <div className="col d-flex justify-content-end">
                     {(reqid != "new" &&
                     userData.IsReportingManager === 1 &&
                     userData.AtlasEmpID != requestDetails.AtlasEmpID)
                      ? (
                       <>
                         <Button
                           type="submit"
                           variant="contained"
                           color="success"
                           className="me-2"
                           onClick={(e) => handleSubmit(e, "approve")}
                         >
                           Approve
                         </Button>
                         <Button
                           type="submit"
                           variant="contained"
                           color="error"
                           onClick={(e) => handleSubmit(e, "reject")}
                         >
                           Reject
                         </Button>
                       </>
                     ) : (
                       ""
                     )}
                     <button
                       type="submit"
                       className={
                         reqid === "new" ||
                         requestDetails.AtlasEmpID != userData.AtlasEmpID ||
                         requestDetails.StatusInd === "C"
                           ? `btn btn-primary mx-2`
                           : "d-none"
                       }
                       onClick={(e) => {
                         handleSubmit(e, "create");
                       }}
                     >
                       Save
                     </button>
                     {userData.AtlasEmpID === requestDetails.AtlasEmpID ? (
                       <>
                         {requestDetails.StatusInd === null ||
                         requestDetails.StatusInd === undefined ||
                         requestDetails.StatusInd === "C" ? (
                           <button
                             type="submit"
                             className="btn btn-primary"
                             onClick={(e) => handleSubmit(e, "submit")}
                           >
                             Submit
                           </button>
                         ) : (
                           ""
                         )}
                       </>
                     ) : (
                       ""
                     )}
                   </div>}
                   
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default LeaveRequestForm;
