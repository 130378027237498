import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaBell, FaUser, FaBars } from "react-icons/fa";
import { MdOutlineApproval } from "react-icons/md";
import { FaEllipsisVertical } from "react-icons/fa6";
import "./Header.css";
import Logo from "../../assets/logo.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { FaClock } from "react-icons/fa";

import { MdApproval } from "react-icons/md";

export function Menu(props) {
  
  const navigate = useNavigate();
  return (
    <>
      {/* <div className="align-middle"> */}
      <div className="">
        <Link to={props.link} className="text-decoration-none ">
          <KeyboardBackspaceIcon
            style={{
              fontWeight: "bold",
              cursor: "pointer",
              marginLeft: "5px",
            }}
          />
          <p className=" p-2 d-inline">Go back</p>
        </Link>
      </div>
    </>
  );
}

const Header = ({ onToggle, sidebarVisible,onToggleReportingManager }) => {
  let reportingManager = null;
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  
  if(userData.IsReportingManager === 1){
     reportingManager = true;
     
  }
  else{
    reportingManager = false;
  }
  
  const [iconsVisible, setIconsVisible] = useState(false);
  const [userMenuVisible, setUserMenuVisible] = useState(false);

  // console.log("Header ")

  const handleIconsVisible = () => {
    setIconsVisible((prev) => !prev);
  };

  const handleUserMenuToggle = () => {
    setUserMenuVisible((prev) => !prev);
  };

  const handleLogout = () => {
    onToggleReportingManager(false);
    localStorage.clear();
    sessionStorage.clear();
    return <Navigate to="/" replace/>;
  };
  

  return (
    <header className="header">
      <div className="title-wrapper">
        <div className="toggle">
          <button
            className="sidebar-toggle border-0 bg-white"
            onClick={onToggle}
          >
            <FaBars />
          </button>
        </div>
        <div className="vertical-dots" onClick={handleIconsVisible}>
          <FaEllipsisVertical />
        </div>
      </div>

      <div className={`icons ${iconsVisible ? "icons-visible" : ""}`}>
        <div className="profile-icon">
          <FaBell className="icon" />
        </div>
        {/* <div className={reportingManager?"d-block":"d-none"}> */}
        <div className="d-block">
          <Link to="/user-sign-in/punchdetails">
        <FaClock className="icon"/>
        </Link>
        </div>
        
        <div className={reportingManager === true ?"d-block":"d-none"}>
          <Link to="/requests/leave-approvals-list">
        <MdOutlineApproval className="icon" />
        </Link>
        </div>
        <div className="dropdown">
          <FaUser className="icon" onClick={handleUserMenuToggle} />
          <div className={`dropdown-menu${userMenuVisible ? " show" : ""}`}>
            <div className="dropdown-item">Profile</div>
            <div className="dropdown-item">Settings</div>
            <Link className="text-decoration-none" to="/user/forgot-password-form">
            <div className="dropdown-item">Reset Password</div>
            </Link>
            <Link className="text-decoration-none" to="/">
              <div className="dropdown-item" onClick={handleLogout}>
                Logout
              </div>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
