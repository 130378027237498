import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Button } from "@mui/material";
import "./TimeManagement.css";
import Loader from "../Loader";

const TimeManagement = () => {
  const [error, setError] = useState("");
  const [punchList, setPunchList] = useState([]);
  const [baseLocation, setIsBaseLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({});

  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));

  const AtlasEmpID = userData.AtlasEmpID;
  const api_url = process.env.REACT_APP_API_URL;

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            setError("Unable to retrieve location.");
            reject(error);
          }
        );
      } else {
        const error = new Error(
          "Geolocation is not supported by this browser."
        );
        setError(error.message);
        reject(error);
      }
    });
  };

  const handleBaseLocationSend = async () => {
    const location = await getCurrentLocation();

    await axios.post(
      `${api_url}/baselocation/sendbaselocation`,
      { userLocation: location, AtlasEmpID: AtlasEmpID },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      throw error;
    }
  };

  const handleValidate = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    if (!AtlasEmpID || !currentDate) {
      alert("AtlasEmpID and PunchDate are required.");
      return false;
    }

    try {
      const response = await axios.post(
        "https://atlas-om.app:30000/api/validatecheckin",
        {
          AtlasEmpID,
          PunchDate: currentDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.IsPunchedIn === true) {
        alert("Already punched in for today.");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error during validation:", error);
      alert(
        "Error during validation: " + error.response?.data?.message ||
          error.message
      );
      return false;
    }
  };

  const handleCheckIn = async () => {
    if (baseLocation === false) {
      alert("You cannot check in without a base location.");
      return;
    }
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();

      const latitude = location.latitude;
      const longitude = location.longitude;
      
      // const latitude = 23.616846
      // const longitude = 58.247159
      if (!latitude || !longitude) {
        throw new Error("Location is not available");
      }
      if (!ipAddress) {
        throw new Error("IP Address is not available");
      }

      const isPunchedIn = await handleValidate();

      if (isPunchedIn === false) {
        const data = {
          userLocation: { latitude, longitude },
          IPAddress: ipAddress,
          AtlasEmpID: AtlasEmpID,
          Operation: "punchin",
          PunchDate: currentDate,
          PunchInTime: currentTime,
        };

        const response = await axios.post(`${api_url}/createpunchdetail`, data);

        if (response.data.success) {
          alert("Check-in successful");
        } else {
          alert("Error during check-in: " + response.data.message);
        }
      }
    } catch (error) {
      console.error("Error during check-in:", error);
      alert(
        "Error during check-in: " + error.response?.data?.message ||
          error.message
      );
    }
  };

  const handleCheckOut = async () => {
    if (baseLocation === false) {
      alert("You cannot check out without a base location.");
      return;
    }
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const currentTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    try {
      const location = await getCurrentLocation();
      const ipAddress = await getIpAddress();
      const { latitude, longitude } = location;
      // const latitude = 23.616846
      // const longitude = 58.247159

      if (!latitude || !longitude || !ipAddress) {
        throw new Error("Location or IP address not available");
      }

      const data = {
        userLocation: { latitude, longitude },
        IPAddress: ipAddress,
        AtlasEmpID: AtlasEmpID,
        Operation: "punchout",
        PunchDate: currentDate,
        PunchOutTime: currentTime,
      };

      const response = await axios.post(`${api_url}/createpunchdetail`, data);

      if (response.data.success) {
        alert("Check-out successful");
      } else {
        alert("Error during check-out: " + response.data.message);
      }
    } catch (error) {
      console.error("Error during check-out:", error);
      alert(
        "Error during check-out: " + error.response?.data?.message ||
          error.message
      );
    }
  };

  const handlegetPunches = async () => {
    const date = new Date();
    const currentDate = date.toISOString().split("T")[0];
    const payload = {
      AtlasEmpID: AtlasEmpID,
      PunchDate: currentDate,
    };
    await axios
      .post(`${api_url}/punch/getlistofpunch`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setPunchList(res.data.data);
      });
  };

  const checkBaseLocationExistence = async (id) => {
    try {
      const response = await axios.get(
        `${api_url}/baselocation/checkbaselocation/${id}`
      );
      if (response.data.isLocationAvailable === false) {
        setIsBaseLocation(false);
        // alert("Please send the base location for approval")
      }
    } catch (err) {
      console.error("There was an error updating the Request!", err);
      // return false;
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await handlegetPunches();
        await checkBaseLocationExistence(AtlasEmpID);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [AtlasEmpID]);

  const handleFetchBaseLocation = async () => {
    const loc = await getCurrentLocation(); // Fetch location again
    setLocation(loc); // Update the state with new location
  };

  const handleOpenModal = async () => {
    await handleFetchBaseLocation(); // Fetch location when opening the modal
  };

  // console.log("baselocation",baseLocation)
  // const mapSrc = location
  // ? `https://maps.google.com/maps?q='+${location.latitude}+','+${location.longitude}+'&hl=en&z=14&amp;output=embed`
  // : '';

  const mapSrc = location.latitude && location.longitude
  ? `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1376.5732871228681!2d${location.longitude}!3d${location.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2som!4v1727679743582!5m2!1sen!2som`
  : "";


  // const mapSrc = location
  // ? `https://www.google.com/maps/embed/v1/view?key=YOUR_API_KEY&center=${location.latitude},${location.longitude}&zoom=14`
  // : '';

  console.log("lat and long",location.latitude,location.longitude);

  // const result = useLocation();
  // console.log("location function call",result);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>

        
          {/* <button type="button"   data-bs-toggle="modal"
                    data-bs-target="#dependentsmodal" onClick={handleBaseLocationSend}>Send Base Location for Approval</button> */}
          <button
            type="button"
            onClick={handleOpenModal}
            data-bs-toggle="modal"
            data-bs-target="#baselocationmodal"
          >
            Send Base Location for Approval
          </button>
          {/* <button className="ms-3" type="button" onClick={checkBaseLocationExistence}>Check base Location</button> */}
          <div className="punch-wrapper">
            <div className="d-flex gap-3">
              {error && <p className="text-danger">{error}</p>}
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleCheckIn}
                  disabled={baseLocation === false}
                >
                  Punch In
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleCheckOut}
                  disabled={baseLocation === false}
                >
                  Punch Out
                </Button>
              </div>
            </div>
            <div
              className="modal fade"
              id="baselocationmodal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="baselocationmodal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="baselocationmodal">
                      Send Base Location
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    {location && (
                      <iframe
                        width="100%"
                        height="300"
                        style={{ border: 0 }}
                        loading="lazy"
                        allowFullScreen=""
                        src={mapSrc}
                      ></iframe>
                       
                      // <iframe
                      //   src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1376.5732871228681!2d58.21857209735558!3d23.60997825819043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2som!4v1727679743582!5m2!1sen!2som"
                      //   width="600"
                      //   height="450"
                      //   // style="border:0;"
                      //   allowfullscreen=""
                      //   loading="lazy"
                      //   referrerpolicy="no-referrer-when-downgrade"
                      // ></iframe>
                    )}
                  </div>
                  <div className="modal-footer">
                    <Link
                      to="/dependent"
                      // state={{ mode: "add", AtlasEmpID: empID }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        endIcon={<AddIcon />}
                        data-bs-dismiss="modal"
                      >
                        Add
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-5">
              <div className="border border-secondary punch-list-header">
                <div className="p-2 d-flex fw-bold shadow justify-content-center blockquote">
                  Punch List - {AtlasEmpID}
                </div>
                <table className="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th scope="col" className="table-header-font-size">
                        SNo
                      </th>
                      <th scope="col" className="table-header-font-size">
                        Punch Date
                      </th>
                      <th scope="col" className="table-header-font-size">
                        in-time
                      </th>
                      <th scope="col" className="table-header-font-size">
                        out-time
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body-font-size">
                    {punchList.map((item, index) => {
                      const sno = index + 1;
                      return (
                        <tr className="py-0" key={index}>
                          <td className="py-0">{sno}</td>
                          <td className="py-0">{item.PunchDate}</td>
                          <td className="py-0">{item.PunchInTime}</td>
                          <td className="py-0">{item.PunchOutTime}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TimeManagement;
