import React from "react";
import { useNavigate } from "react-router-dom";
import "./HRDashboard.css";
import { FaUsersCog, FaRegEnvelope,FaDatabase,FaUserPlus } from "react-icons/fa";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { FaPlaneCircleExclamation,FaFileCircleCheck } from "react-icons/fa6";
import { GrUserNew } from "react-icons/gr";
import { RiFolderUserFill,RiFileUserLine  } from "react-icons/ri";
import { GiWeightLiftingUp,GiReceiveMoney,GiFamilyTree,GiPayMoney } from "react-icons/gi";
import { FaRegFile } from "react-icons/fa6";
import { FaFile } from "react-icons/fa6";
import { TbPigMoney } from "react-icons/tb";
import { Link } from "react-router-dom";

import axios from "axios";

const HRDashboard = ({onToggle}) => {
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("CurrentUserData"));
  const token = localStorage.getItem("token");


  if (!token) {
    navigate("/");
  }
  if(userData.AtlasEmpID!="AEC002"){
    navigate("/timesheetMgmt")
  }
  // const handlesubmit = async()=>{
  //   await handleAPi();
  // }

  // const handleAPi = async () => {
  //   const currentLocation = {
  //     latitude: 23.7081267,
  //     longitude: 58.7081267,
  //   };
  
  //   try {
  //     const response = await axios.post(
  //       'https://atlas-om.app:30000/api/timesheet/checklocation',
  //       {
  //         userLocation: currentLocation
  //       },      
  //     );
  //     console.log(response, 'response');
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  
  return (
    <>
      <div>
        <div className="container-fluid page-title">
          <div className="container my-3 px-2">
          <h4 className="mb-2">Employee Management</h4>
            <div className="row g-4">
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/empmgmt" className="text-decoration-none">
                <div className="border dashboard-card-wrapper employees">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Employees
                    <RiFolderUserFill className="dashboard-card-icon" />
                  </div>
                  
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/requests/leave-approvals-list" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper request-approvals">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Leave Approvals
                    <FaFileCircleCheck  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/payroll" className="text-decoration-none">
              <div className="p-2 border  dashboard-card-wrapper payroll">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Payroll
                    <GiReceiveMoney  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="border  dashboard-card-wrapper ffs">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Full & Final Settlement
                    <FaPlaneCircleExclamation className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="border performance  dashboard-card-wrapper">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Performance
                    <GiWeightLiftingUp className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
            </div>
            </div>
            <div className="container my-3 px-2">
            <h4 className="mb-2">Talent Management</h4>
            <div className="row g-3">
            <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/hr/recruitment-plan-list" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper recruitment-plan">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Recruitment Plan
                    <FaUserPlus className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="p-2 border dashboard-card-wrapper manpower-request">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Manpower Request
                    <GrUserNew className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/hr/recruitment-master-list" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper recruitment-process">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Recruitment Process
                    <FaUserPlus className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
            <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/cvdb" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper applicant-db">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Applicant Database
                    <RiFileUserLine  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
            </div>
            </div>
            <div className="container my-3 px-2">
            <h4 className="mb-2">HR Utilities</h4>
            <div className="row g-3">
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/circulars" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper circulars">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Circulars
                    <SiAmazonsimpleemailservice className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper organization">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                   Organization
                    <GiFamilyTree className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper pasi">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    PASI
                    <GiPayMoney  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/hrdocs" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper documents">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Documents
                    <FaFile className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="/hrformsmgmt" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper applicant-db">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    HR Forms
                    <RiFileUserLine  className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
            </div>
            </div>
            <div className="container my-3 px-2">
            <h4 className="mb-2">Reports</h4>
            <div className="row g-3">
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper leave-report">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Leave Report
                    <SiAmazonsimpleemailservice className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper timesheet-report">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    TimeSheet Report
                    <SiAmazonsimpleemailservice className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="border  dashboard-card-wrapper leave-planner">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Leave Planner
                    <FaPlaneCircleExclamation className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-2" onClick={onToggle}>
              <Link to="#" className="text-decoration-none">
                <div className="p-2 border  dashboard-card-wrapper gratuity">
                  <div className="fs-6 fw-semibold d-flex flex-column align-items-center">
                    Gratuity Reports
                    <TbPigMoney className="dashboard-card-icon" />
                  </div>
                </div>
                </Link>
              </div>
              </div>
            </div>

            
          
        </div>
      </div>
      {/* <div>
      <button onClick={handlesubmit}>Get Location</button>
      
        <div>
          <h3>Location:</h3>
          <p>Latitude: </p>
          <p>Longitude: </p>0
        </div>
     
    </div> */}

    </>
  );
};

export default HRDashboard;
