import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";

import { nationalities } from "../../../../data/nationalities";

import Loader from "../../../Loader";


import "./RecruitmentMasterForm.css";

const RecruitmentMasterForm = ( {mode, recid}) => {

  console.log("recid",recid)
  const api_url = process.env.REACT_APP_API_URL;

  const formRef = useRef(null);

  // latest addition

  const navigate = useNavigate();
  const [recruitment, setRecruitment] = useState({});
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [reportingManagers, setReportingManagers] = useState([]);
  const [selectedDept, setSelectedDept] = useState("");
  const [loading, setLoading] = useState(false);
  const [fixedSalary, setTotalFixedSalary] = useState(0);
  const [years, setYears] = useState([]);
  
  const [allowance_List, setAllowanceList] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const getTotalFixedSalary = (e, index) => {
    let total = 0;
    let temp = [...allowance_List];
    temp[index] = Number(e.target.value);
    setAllowanceList(temp);
    temp.forEach((item) => {
      total = total + item;
    });
    setTotalFixedSalary(total);
    setRecruitment((prev) => ({
      ...prev,
      TotalFixedSalary: total,
    }));
  };

  const getDepartments = async () => {
    try {
      const response = await axios.get(`${api_url}/emp/GetDepartments`);
      let result = response.data.results;
      setDepartments(result);
      setSelectedDept("");
      return result;
    } catch (error) {
      console.error("There was an error fetching the departments!", error);
    }
  };

  const getDesignations = async(e) => {
    await axios
      .get(`${api_url}/emp/GetDesignations/${e}`)
      // .get(`${api_url}/emp/GetDesignations/${e}`)
      .then((response) => {
        setDesignations(response.data);
      })
      .catch((err) => {
        console.error("Error fetching Desingations", err);
      });
  };

  const getReportingManager = async() => {
    await axios.get(`${api_url}/emp/GetManagersList`).then((response) => {
      setReportingManagers(response.data);
    });
  };

  const getUserDetails = async (id) => {
    try {
      const res = await axios.get(`${api_url}/recruitment/recruitment-master/edit-recruitment-details/${id}`);
      // setSignatureImage(res.data.imgPath);
      console.log("response from get api call",res.data.data)
      let emp = res.data.data;
      let depts = await getDepartments();
      let dept_id = depts.find(
        (dept) => dept.DepartmentName === emp.Department
      ).DeptID;
      getDesignations(dept_id);
      setSelectedDept(emp.Department);
      setRecruitment(emp);
      let payroll_allowance_List = [
        emp.Basic,
        emp.HRA,
        emp.TA,
        emp.SA,
        emp.CA,
        emp.DA,
        emp.FA,
        emp.OA,
      ];
      setAllowanceList(payroll_allowance_List);
    } catch (error) {
      console.error(error);
    } 
  };

  const updateRecruitmentDetails = async (eid) => {
    try {
      await axios.put(`${api_url}/recruitment/recruitment-master/update-recruitment-details/${eid}`, recruitment);
      return true;
    } catch (error) {
      console.error("There was an error updating the recruitment!", error);
      return false;
    } 
  };

  useEffect(() => {
       const fetchData = async () => {
      setLoading(true); 
      try {
        await getDepartments(); 
        if (mode === "edit") {
          await getUserDetails(recid); 
        }
        await getReportingManager(); 
        await YearSelect(); 
      } catch (error) {
        console.error("Error fetching data:", error); 
      } finally {
        setLoading(false); 
      }
    };
    fetchData();   
  }, [recid,mode]);

  //Handle the input values and update in state
  const handleInputChanges = (event, field) => {
    let type = event.target.type;
    let val = event.target.value;
    if((type==="date")&& (val==="")){
      val = null;
    }
    //a function to handle input event, while receiving event and fieldname parameter and updates the state.
    let emp = { ...recruitment }; //emp is a temp varaible to store spread of recruitment state object which recruitment spreads its attributes to emp object
    emp[field] = val //since it is a dynamic key name we are using emp[field] and capturing input value and assigning to it
    setRecruitment(emp); //adding the new object to state.
    // console.log(`${field}: ${event.target.value}`);
  };


  const handleDepartmentChange = async(e) => {
    let value = e.target.value;
    if (value == "") {
      setSelectedDept(value);
      setDesignations([]);
    } else {
      console.log("departments state before deptid line",departments)
      const deptId = departments.find(
        (dept) => dept.DepartmentName === e.target.value
      ).DeptID;
      setSelectedDept(value);
      await getDesignations(deptId);
    }
  };
  const YearSelect = async () => {
    const startYear = 1980;
    const endYear = 2024;
    const years_list = [];

    for (let year = startYear; year <= endYear; year++) {
      years_list.push(year);
    }
    setYears(years_list);
  };

  // --------------------------------------------------------------------

  // const getLatestID = async() => {
  //   await axios
  //     // .get(`${api_url}/emp/nextempid`)
  //     .get(`${api_url}/recruitment/recruitment-master/latest-recruitment-id`)
  //     .then((res) => {
       
  //       const recruitmentID = res.data.RecruitmentID;
           
  //       const temp = { ...recruitment }; 
  //       temp["RecruitmentID"] = recruitmentID;
  //       setRecruitment(temp);
  //       return true
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const getLatestID = async () => {
    try {
      const res = await axios.get(`${api_url}/recruitment/recruitment-master/latest-recruitment-id`);
      const recruitmentID = res.data.RecruitmentID;
      return recruitmentID;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  

  const InsertRecruitmentDetails = async () => {
    let payload = { 
      FirstName: recruitment.FirstName,
      LastName: recruitment.LastName,
      Department: recruitment.Department,
      Designation: recruitment.Designation,
      ReportingManager: recruitment.ReportingManager
     };

      try {
        const response = await axios.post(`${api_url}/recruitment/recruitment-master/create-recruitment-details`, payload, {
          headers: {
            "Content-Type": "application/json", 
          },
        });
        if(response.status === 200){
          const getlatestid = await getLatestID();
          // if(getlatestid === true){
            console.log("recruitment id before accessing update function",getlatestid);
            const updateSuccess = await updateRecruitmentDetails(getlatestid);
            return updateSuccess; 
          // }
        }  
        else{
          return false
        } 
      } catch (error) {
        console.log("Error creating recruitment:", error);
        return false; 
      }
  
  };
  

  const handleSubmit = async (eve) => {
    eve.preventDefault();
  
    let success = false;
  
    if (mode === "add") {
      success = await addRecruitmentDetails();
    } else {
      success = await editRecruitmentDetails(recid);
    }
  
    if (success) {
      toast.success(mode === "add" ? "Recruitment Details Added Successfully!" : "Recruitment Details Updated Successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    } else {
      toast.error(mode === "add" ? "Recruitment Details Addition Failed" : "Recruitment Details Updation Failed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        theme: "light",
      });
    }
  };
  
  const addRecruitmentDetails = async () => {
    const createSuccess = await InsertRecruitmentDetails();
    if (createSuccess) {
      return true; // Employee was successfully created and updated
    }
    return false; // Indicate failure
  };
const editRecruitmentDetails = async (empid) => {
  return await updateRecruitmentDetails(empid);
};

  // --------------------------------------------------------------------------------------------------


 

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid border p-2">
          <form
            className=""
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className="d-flex justify-content-between">
              {/* <Menu link="/hr/recruitment-master-list" /> */}
              <div className="emp-form-save-button">
                <Button type="submit" variant="contained" color="primary">
                  Save Details
                </Button>
              </div>
            </div>

            <div className="emp-form d-block">
              {/* Form Starts */}
              <div className="container form-wrapper border border-secondary">
                <div className="form">
                  {/* Basic overview */}
                  <div className="row sub-wrapper ">
                    <h5 className="sub-wrapper-heading">Basic Overview</h5>
                    <div className="col">
                      <div className="row  inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label customlabel"
                            htmlFor="firstname"
                          >
                            First Name<span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="firstname"
                            placeholder=""
                            // disabled={mode === "edit"}
                            value={recruitment.FirstName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "FirstName");
                            }}
                            required
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label customlabel"
                            htmlFor="lastname"
                          >
                            Last Name<span className="required"> *</span>
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="lastname"
                            placeholder=""
                            // disabled={mode === "edit"}
                            value={recruitment.LastName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "LastName");
                            }}
                            required
                          />
                        </div>
                        
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="department">
                            Department<span className="required"> *</span>
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "Department");
                              handleDepartmentChange(e);
                            }}
                            value={recruitment.Department || ""}
                            name="department"
                            className="form-input-select"
                            id="department"
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Department</option>
                            {departments.map((dept) => (
                              <option
                                key={dept.DeptID}
                                value={dept.DepartmentName}
                              >
                                {dept.DepartmentName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="designation">
                            Designation<span className="required"> *</span>
                          </label>
                          <select
                            className="form-input-select"
                            onChange={(e) => {
                              handleInputChanges(e, "Designation");
                            }}
                            id="designation"
                            name="designation"
                            value={recruitment.Designation || ""}
                            disabled={!selectedDept}
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Designation</option>
                            {designations.map((item) => (
                              <option
                                key={item.DesigID}
                                value={item.DesignationName}
                              >
                                {item.DesignationName}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="reporting-manager"
                          >
                            Reporting Manager
                            <span className="required"> *</span>
                          </label>
                          <select
                            className="form-input-select"
                            onChange={(e) => {
                              handleInputChanges(e, "ReportingManager");
                            }}
                            id="reporting-manager"
                            name="reporting-manager"
                            value={recruitment.ReportingManager || ""}
                            aria-label=".form-select-sm example"
                            required
                          >
                            <option value="">Select Reporting Manager</option>
                            {reportingManagers.map((item) => (
                              <option
                                key={item.AtlasEmpID}
                                value={item.AtlasEmpID}
                              >
                                {item.AtlasEmpID} - {item.EmpName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row inner-row">
                       
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="add-emp-employee-type"
                          >
                            Employee Type
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "EmployeeType");
                            }}
                            value={recruitment.EmployeeType}
                            name="employee-type"
                            className="form-input-select"
                            id="add_emp_employee_type"
                            aria-label=".form-select-sm example"
                          >
                            <option value="">Select Employee type</option>
                            <option value="O">Own Staff</option>
                            <option value="S">Sub Contract</option>
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="add-emp-notice-period"
                          >
                            Notice Period
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "NoticePeriod");
                            }}
                            value={recruitment.NoticePeriod}
                            name="notice-period"
                            className="form-input-select"
                            id="add_emp_notice_period"
                            aria-label=".form-select-sm example"
                          >
                            <option value="">Select Notice Period</option>
                            <option value="30 Days">30 Days</option>
                            <option value="60 Days">60 Days</option>
                            <option value="90 Days">90 Days</option>
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="employeeworkplace">
                            Employee Work Place
                          </label>
                          <select
                            name="employeeworkplace"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            onChange={(e) => {
                              handleInputChanges(e, "EmployeeWorkPlace");
                            }}
                            value={recruitment.EmployeeWorkPlace || ""}
                          >
                            <option value="">Select Work place</option>
                            <option value="In House">In House</option>
                            <option value="On Site">On Site</option>
                            </select>
                        </div>
                      </div>
                      <div className="row inner-row">
                      <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="joiningDate">
                            Joining Date
                          </label>
                          <input
                            id="joiningDate"
                            className="form-input-text"
                            type="date"
                            value={recruitment.JoiningDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "JoiningDate");
                            }}
                          />
                        </div>
                     
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="empcategory">
                            Employee Category
                          </label>
                          <select
                            name="empcategory"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            value={recruitment.EmployeeCategory || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "EmployeeCategory");
                            }}
                          >
                            <option value="">Select Employee Category</option>
                            <option value="Category-A">Category A</option>
                            <option value="Cateogory-B">Category B</option>
                            <option value="Category-C">Category C</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Primary Information */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">Personal Information</h5>
                    <div className="col">
                      <div className="row inner-row">
                      <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="nationality">
                            Nationality
                          </label>
                          <select
                            name="nationality"
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                            onChange={(e) => {
                              handleInputChanges(e, "Nationality");
                            }}
                            value={recruitment.Nationality || ""}
                          >
                          <option value="">Select Nationality</option>
                        {
                          nationalities.map((item,index)=>(
                            <option key={index} value={item}>{item}</option>
                          ))
                        }
                          </select>
                        </div>
                       
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="personalemailid"
                          >
                            Personal Email ID
                          </label>
                          <input
                            type="email"
                            className="form-input-email"
                            id="personalemailid"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "PersonalEmailID");
                            }}
                            value={recruitment.PersonalEmailID || ""}
                            maxLength="50"
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="personalmobilenumber"
                          >
                            Personal Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="personalmobilenumber"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "PersonalMobileNumber");
                            }}
                            value={recruitment.PersonalMobileNumber || ""}
                            maxLength="50"
                          />
                        </div>
                        
                        
                      </div>
                      <div className="row inner-row">
                      <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="dob">
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="dob"
                            placeholder=""
                            value={recruitment.DateofBirth || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "DateofBirth");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="gender">
                            Gender
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "Gender");
                            }}
                            name="gender"
                            value={recruitment.Gender || ""}
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                          >
                            <option defaultValue={""} value="">
                              Select Gender
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="maritalstatus">
                            Marital Status
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "MaritalStatus");
                            }}
                            name="maritalstatus"
                            value={recruitment.MaritalStatus || ""}
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                          >
                            <option defaultValue={""} value="Male">
                              Select Marital Status
                            </option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                          </select>
                        </div>
                      
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportnumber"
                          >
                            Passport Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="passportnumber"
                            placeholder=""
                            maxLength="20"
                            value={recruitment.PassportNumber || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "PassportNumber");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportissuedate"
                          >
                            Passport Issue Date
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="passportissuedate"
                            placeholder=""
                            value={recruitment.PassportIssueDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "PassportIssueDate");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportexpiry"
                          >
                            Passport Expiry
                          </label>
                          <input
                            type="date"
                            className="form-input-date"
                            id="passportexpiry"
                            placeholder=""
                            value={recruitment.PassportExpiry || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "PassportExpiry");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="passportissueplace"
                          >
                            Passport Issue Place
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="passportissueplace"
                            placeholder=""
                            value={recruitment.PassportIssuePlace || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "PassportIssuePlace");
                            }}
                          />
                        </div>
                      </div>
                     
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="civildnumber">
                            Civil ID Number
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="civildnumber"
                            placeholder=""
                            value={recruitment.CivilIDNumber || ""}
                            maxLength="20"
                            onChange={(e) => {
                              handleInputChanges(e, "CivilIDNumber");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="civilissuedate"
                          >
                            Civil Issue Date
                          </label>
                          <input
                            id="civilissuedate"
                            className="form-input-date"
                            type="date"
                            value={recruitment.CivilIssueDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "CivilIssueDate");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="civilexpirydate"
                          >
                            Civil Expiry Date
                          </label>
                          <input
                            id="civilexpirydate"
                            className="form-input-date"
                            type="date"
                            value={recruitment.CivilExpiryDate || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "CivilExpiryDate");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-6 col-9">
                          <label
                            className="form-label"
                            htmlFor="localresidentialaddress"
                          >
                            Local Residential Address
                          </label>
                          <textarea
                            className="form-input-textarea"
                            id="localresidentialaddress"
                            rows="3"
                            columns="6"
                            onChange={(e) => {
                              handleInputChanges(e, "LocalResidentialAddress");
                            }}
                            value={recruitment.LocalResidentialAddress || ""}
                          ></textarea>
                        </div>
                        <div className="col-md-6 col-9">
                          <label
                            className="form-label"
                            htmlFor="permanentaddress"
                          >
                            Permanent Address
                          </label>
                          <textarea
                            className="form-input-textarea"
                            id="permanentaddress"
                            rows="3"
                            columns="3"
                            onChange={(e) => {
                              handleInputChanges(e, "PermanentAddress");
                            }}
                            value={recruitment.PermanentAddress || ""}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Career History */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">CAREER HISTORY</h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="qualificationname"
                          >
                            Highest Qualification
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="qualificationname"
                            placeholder=""
                            value={recruitment.QualificationName || ""}
                            maxLength="100"
                            onChange={(e) => {
                              handleInputChanges(e, "QualificationName");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="coursecompletedyear"
                          >
                            Course Completed Year
                          </label>
                          <select
                            onChange={(e) => {
                              handleInputChanges(e, "CourseCompletedYear");
                            }}
                            name="coursecompletedyear"
                            value={recruitment.CourseCompletedYear || ""}
                            className="form-input-select"
                            aria-label=".form-select-sm example"
                          >
                            <option value="">Select the Year</option>
                            {/* {years.map} */}
                            {years.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="universitycollege"
                          >
                            University / College
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="universitycollege"
                            placeholder=""
                            value={recruitment.UniversityCollege || ""}
                            maxLength="200"
                            onChange={(e) => {
                              handleInputChanges(e, "UniversityCollege");
                            }}
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="lastcompanyname"
                          >
                            Last Company Name
                          </label>
                          <input
                            type="text"
                            className="form-input-text"
                            id="lastcompanyname"
                            placeholder=""
                            value={recruitment.LastCompanyName || ""}
                            maxLength="50"
                            onChange={(e) => {
                              handleInputChanges(e, "LastCompanyName");
                            }}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="yearsinlastcompany"
                          >
                            Years In Last Company
                          </label>
                          <input
                            type="number"
                            className="form-input-number"
                            id="yearsinlastcompany"
                            placeholder=""
                            value={recruitment.YearsInLastCompany || ""}
                            onChange={(e) => {
                              handleInputChanges(e, "YearsInLastCompany");
                            }}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                        <div className="col-9 col-md-4">
                          <label
                            className="form-label"
                            htmlFor="totalexperience"
                          >
                            Total Experience
                          </label>
                          <input
                            type="number"
                            className="form-input-number"
                            id="totalexperience"
                            placeholder=""
                            value={recruitment.TotalYearsExpWhileJoining || ""}
                            onChange={(e) => {
                              handleInputChanges(
                                e,
                                "TotalYearsExpWhileJoining"
                              );
                            }}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onKeyDown={handleKeyDown}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Salary Structure */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">Salary Structure</h5>
                    <div className="col">
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="basic">
                            Basic Pay
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="basic"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "Basic");
                              getTotalFixedSalary(e, 0);
                            }}
                            value={recruitment.Basic || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="hra">
                            HR Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="hra"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "HRA");
                              // handlePayroll(e, "HRA");
                              getTotalFixedSalary(e, 1);
                            }}
                            value={recruitment.HRA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="ta">
                            Travel Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="ta"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "TA");
                              // handlePayroll(e, "TA");
                              getTotalFixedSalary(e, 2);
                            }}
                            value={recruitment.TA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="sa">
                            Special Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="sa"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "SA");
                              // handlePayroll(e, "SA");
                              getTotalFixedSalary(e, 3);
                            }}
                            value={recruitment.SA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="ca">
                            Car Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="ca"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "CA");
                              // handlePayroll(e, "CA");
                              getTotalFixedSalary(e, 4);
                            }}
                            value={recruitment.CA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="da">
                            Desert Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="da"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "DA");
                              // handlePayroll(e, "DA");
                              getTotalFixedSalary(e, 5);
                            }}
                            value={recruitment.DA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="fa">
                            Food Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="fa"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "FA");
                              // handlePayroll(e, "FA");
                              getTotalFixedSalary(e, 6);
                            }}
                            value={recruitment.FA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label className="form-label" htmlFor="oa">
                            Other Allowance
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="oa"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "OA");
                              // handlePayroll(e, "OA");
                              getTotalFixedSalary(e, 7);
                            }}
                            value={recruitment.OA || ""}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row inner-row">
                        <div className="col-md-2 col-5">
                          <label
                            className="form-label"
                            htmlFor="totalfixedsalary"
                          >
                            Total Fixed Salary
                          </label>
                        </div>
                        <div className="col-md-2 col-5">
                          <input
                            type="number"
                            className="form-input-number"
                            id="totalfixedsalary"
                            placeholder=""
                            onChange={(e) => {
                              handleInputChanges(e, "TotalFixedSalary");
                              // handlePayroll(e, "Total");
                            }}
                            value={
                              fixedSalary || recruitment.TotalFixedSalary || 0
                            }
                            // value={recruitment.TotalFixedSalary || fixedSalary}
                            min={0}
                            onKeyDown={handleKeyDown}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Compensation Details */}
                  <div className="row sub-wrapper">
                    <h5 className="sub-wrapper-heading">
                      {" "}
                      Compensation Details
                    </h5>
                    <div className="row inner-row">
                      <div className="col-md-2 col-5 ">
                        <label
                          className="form-label"
                          htmlFor="mobilentitlement"
                        >
                          Mobile Entitlement
                        </label>
                      </div>
                      <div className="col-md-2 col-5 ">
                        <input
                          type="number"
                          className="form-input-number"
                          id="mobilentitlement"
                          placeholder=""
                          onChange={(e) => {
                            handleInputChanges(e, "MobileEntitlement");
                          }}
                          value={recruitment.MobileEntitlement || ""}
                          min={0}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      </div>
                      <div className="col-md-2 col-5 ">
                        <label className="form-label" htmlFor="airfarevalue">
                          Air Fare Value
                        </label>
                      </div>
                      <div className="col-md-2 col-5 ">
                        <input
                          type="number"
                          className="form-input-number"
                          id="airfarevalue"
                          placeholder=""
                          onChange={(e) => {
                            handleInputChanges(e, "AirFareValue");
                          }}
                          value={recruitment.AirFareValue || ""}
                          min={0}
                          onKeyDown={handleKeyDown}
                          onFocus={(e) =>
                            e.target.addEventListener(
                              "wheel",
                              function (e) {
                                e.preventDefault();
                              },
                              { passive: false }
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* Bank Information and Digital Signatures */}
                  <div className="row sub-wrapper  ">
                    <h5 className="sub-wrapper-heading">
                      Remarks
                    </h5>
                    <div className="col">
                      <div className="row inner-row">
                      <div className="col-9 col-md-4">
                          <label className="form-label" htmlFor="remarks">
                            Remarks
                          </label>
                          <textarea
                            onChange={(e) => {
                              handleInputChanges(e, "Remarks");
                            }}
                            value={recruitment.Remarks || ""}
                            className="form-control"
                            id="add_emp_remarks"
                            rows="3"
                            columns="6"
                            maxLength="1000"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Submit Button */}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default RecruitmentMasterForm;
